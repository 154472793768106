import { CheckSmall, PlusSmall } from "components";
import { InputHTMLAttributes } from "react";
import { twMerge } from "tailwind-merge";

type Variant = "default" | "custom";

export interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  checked: boolean;
  variant?: Variant;
  checkedClasses?: string;
}

export const Radio = ({
  label,
  checked,
  variant = "default",
  checkedClasses = "",
  onChange,
  disabled = false,
  className,
  ...rest
}: RadioProps) => {
  const isDefault = variant === "default";
  return (
    <label
      className={twMerge(
        "h-[44px] min-w-[155px] cursor-pointer",
        !disabled && "cursor-pointer",
        disabled && "pointer-events-none",
        className
      )}
      key={label}
    >
      <input className="sr-only" checked={checked} disabled={disabled} type="radio" onChange={onChange} {...rest} />
      <div
        className={twMerge(
          "flex items-center justify-between border border-primary bg-white focus:outline-none",
          disabled && "border-primary/20 text-primary/20",
          !isDefault && "h-full",
          !isDefault && checked && checkedClasses
        )}
      >
        <span className="flex-1 overflow-x-scroll text-center text-sm font-light">{label}</span>
        {isDefault && (
          <span
            className={twMerge(
              "p-4",
              checked ? (disabled ? "bg-success/20" : "bg-success") : disabled ? "bg-primary/20" : "bg-primary"
            )}
          >
            {checked ? <CheckSmall /> : <PlusSmall />}
          </span>
        )}
      </div>
    </label>
  );
};
