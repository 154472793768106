import { useAppDispatch } from "app/hooks";
import { Button, InputWithButton, Sort, Search } from "components";
import { useAdminManagementContext } from "pages/AdminManagement";
import { UserModal } from "pages/AdminManagement/components/UserModal";
import { USER_SORTS, UserSortItem } from "helpers";
import { ChangeEvent, useEffect, useState } from "react";
import { initialUserData, setUserData } from "services/userSlice";

export const AdminHeader = () => {
  const dispatch = useAppDispatch();
  const { onSearch, onSort, params } = useAdminManagementContext();
  const [openModal, setOpenModal] = useState(false);
  const [inputValue, setInputValue] = useState(params.search || "");

  useEffect(() => {
    if (!openModal) dispatch(setUserData(initialUserData));
  }, [openModal]);

  return (
    <>
      <div className="flex flex-col gap-y-3 xl:flex-row xl:justify-evenly">
        <div>
          <InputWithButton
            inputProps={{
              placeholder: "Search",
              variant: "modal",
              value: inputValue,
              onChange: (event: ChangeEvent<HTMLInputElement>) => setInputValue(event.target.value),
              className: "border-primary focus:border-primary",
            }}
            buttonProps={{
              children: <Search className="w-10" />,
              className: "px-6",
              onClick: () => onSearch(inputValue),
            }}
          />
        </div>
        <Sort
          label="Sort by:"
          values={USER_SORTS}
          onSort={(sort) => onSort(sort as UserSortItem)}
          selectedSort={params.sort}
        />
        <div>
          <Button onClick={() => setOpenModal(true)} className="w-full uppercase">
            Add New User
          </Button>
        </div>
      </div>
      {openModal && <UserModal open={openModal} setOpen={setOpenModal} variant="add" />}
    </>
  );
};
