import { useState } from "react";
import { useGetNotificationsQuery, useMarkAsReadNotificationMutation } from "services";
import { Bell, ChevronLeft, ChevronRight, Loading, Button, Dropdown } from "components";

export const NotificationsDropdown = () => {
  const { data: notifications, refetch } = useGetNotificationsQuery({});
  const { data: unreadNotifications, refetch: refetchUnread } = useGetNotificationsQuery({ is_read: false });
  const [readNotification] = useMarkAsReadNotificationMutation();
  const [loading, setLoading] = useState<boolean>(false);

  const [index, setIndex] = useState(0);

  const markAsRead = async (id: number) => {
    setLoading(() => true);
    await readNotification(id);
    await refetch();
    setLoading(() => false);
    await refetchUnread();
  };
  return (
    <Dropdown>
      <>
        <Dropdown.Trigger>
          <div className="cursor-pointer">
            <Bell />
          </div>
        </Dropdown.Trigger>
        {!!notifications && notifications.length > 0 && (
          <Dropdown.Items
            className="w-96"
            customOpen={unreadNotifications && unreadNotifications.length > 0 ? true : undefined}
          >
            {loading && <Loading absolute />}
            <div className="space-y-4 p-4">
              <p className="text-base">Notification tag(s): {notifications[index].notification_tags.join(" | ")}</p>
              <p className="text-base">
                <strong>Author:</strong> {notifications[index].author.name}
              </p>
              <p className="text-base">“{notifications[index].notification}”</p> {notifications[index].notification_id}
              <div className="flex items-center justify-between">
                <Button
                  className={`px-6 uppercase ${notifications[index].is_read ? "bg-secondary" : "bg-success"}`}
                  onClick={() => markAsRead(notifications[index].notification_id as number)}
                  disabled={loading}
                >
                  {notifications[index].is_read ? "Read" : "Mark as read"}
                </Button>
                <div className="flex items-center space-x-0.5">
                  <Button
                    className="w-fit border border-primary bg-white p-2 px-3 disabled:cursor-auto disabled:opacity-20"
                    onClick={() => setIndex(index - 1)}
                    disabled={index === 0}
                  >
                    <ChevronLeft className="text-primary" />
                  </Button>
                  <Button
                    className="w-fit border border-primary bg-white p-2 px-3 disabled:cursor-auto disabled:opacity-20"
                    onClick={() => setIndex(index + 1)}
                    disabled={index === notifications.length - 1}
                  >
                    <ChevronRight className="text-primary" />
                  </Button>
                </div>
              </div>
            </div>
          </Dropdown.Items>
        )}
      </>
    </Dropdown>
  );
};
