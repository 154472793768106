import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { UserState, storeUser, storeUserName } from "helpers";
import { AWSAuth } from "authentication";
import { Button, ButtonVariant, CardHeader, InputWithButton, InputWithButtonI, ValidationMessage } from "components";
import { Link } from "react-router-dom";
import { useLazyGetProfileQuery, useRegisterDeviceMutation } from "services";
import { useAuthContext } from "context";

const initialData: InputWithButtonI = {
  value: "",
  status: "initial",
  error: "",
};

interface Auth2FAProps extends Pick<UserState, "user"> {}

export const Auth2FA = ({ user }: Auth2FAProps) => {
  const { setAuth } = useAuthContext();

  const [code, setCode] = useState<InputWithButtonI>(initialData);
  const [disabledCode, setDisabledCode] = useState<boolean>(true);
  const [disabled, setDisabled] = useState<boolean>(true);

  const [username, setUsername] = useState("");

  const [getProfile] = useLazyGetProfileQuery();
  const [registerDevice, { isLoading, isError, error }] = useRegisterDeviceMutation();

  const returnCodeText = code.status === "initial" ? "Confirm" : code.status === "success" ? "Confirmed" : "Try again";

  const returnButtonVariant = ({ status }: InputWithButtonI): ButtonVariant =>
    status === "initial" ? "bg-primary" : status === "success" ? "bg-success" : "bg-error";

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { value },
    } = event;

    setCode((oldObject: InputWithButtonI) => ({
      ...oldObject,
      value,
    }));
  };

  const onSubmitHandler = (event: FormEvent<HTMLFormElement | HTMLButtonElement>): void => {
    event.preventDefault();
    if (code.status !== "success") onVerifyCode(code.value);
  };

  const onVerifyCode = async (code: string) => {
    try {
      setDisabledCode(() => true);
      await AWSAuth.confirmSignIn(user, code);
      setCode((oldObject: InputWithButtonI) => ({
        ...oldObject,
        status: "success",
      }));
    } catch (err: any) {
      console.error(err);
      setCode((oldObject: InputWithButtonI) => ({
        ...oldObject,
        error: err.message ? err.message : err,
        status: "error",
      }));
    } finally {
      setDisabledCode(() => false);
    }
  };

  const sendDeviceData = async (username: string, deviceKey: string, deviceName: string) => {
    try {
      const result = await registerDevice({ username, deviceKey, deviceName });
      //TODO: DELETE LOGS
      console.log("Device registration result:", result);
    } catch (error) {
      console.error("Error registering device:", error);
    }
  };

  const onContinueHandler = () => {
    setAuth("success");
  };

  useEffect(() => {
    code.error &&
      setCode((oldObject: InputWithButtonI) => ({
        ...oldObject,
        error: "",
      }));
    setDisabledCode(!code.value);
  }, [code.value]);

  useEffect(() => {
    setDisabled(code.status !== "success");
    if (code.status === "success") {
      storeUser(user);
      const username = localStorage.getItem("username") as string;
      const deviceKey = localStorage.getItem("deviceKey") as string;
      const deviceName = localStorage.getItem("deviceName") as string;

      //TODO: izbrisi log
      console.log(user);
      console.log(username);
      console.log(deviceKey);
      console.log(deviceName);

      sendDeviceData(username, deviceKey, deviceName);

      getProfile();
    }
  }, [code.status]);

  return (
    <div className="space-y-5">
      <CardHeader
        title="Two-factor authentication"
        paragraph="You have activated 2FA, please enter the sms code to continue."
      />
      <form onSubmit={onSubmitHandler}>
        <div className="flex flex-col">
          <div className="flex">
            <InputWithButton
              inputProps={{
                id: "code",
                name: "code",
                value: code.value,
                onChange: onChangeHandler,
                placeholder: "Enter SMS code",
                maxLength: 6,
                minLength: 6,
                readOnly: code.status === "success",
              }}
              buttonProps={{
                type: "submit",
                variant: returnButtonVariant(code),
                disabled: disabledCode,
                className: "min-w-[150px] max-w-[150px] px-2",
                children: returnCodeText,
              }}
            />
          </div>
          <ValidationMessage>{code.error}</ValidationMessage>
          <div className="mt-7 flex flex-1">
            {code.status !== "success" && (
              <div className="flex flex-1 items-center justify-center">
                <Link to="/">I can't find the SMS. Help!</Link>
              </div>
            )}
            <div className="flex flex-1 justify-center">
              <Button
                disabled={disabled}
                className={code.status === "success" ? "mx-auto mt-5 px-16 sm:min-w-[200px]" : undefined}
                onClick={onContinueHandler}
              >
                Enter PARKBER
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
